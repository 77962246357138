import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'coverEmailPhone',
})
export class EmailPhoneCoverPipe implements PipeTransform {
  transform(value: string, type: 'email' | 'phone'): string {
    if (!value) {
      return '';
    }

    if (type === 'email') {
      return this.maskEmail(value);
    }

    if (type === 'phone') {
      return this.maskPhone(value);
    }

    return value;
  }

  private maskEmail(email: string): string {
    const [localPart, domain] = email.split('@');
    if (localPart.length <= 2) {
      return `${localPart}*****@${domain}`;
    }

    const firstChar = localPart.charAt(0);
    const masked = '*'.repeat(localPart.length - 1);

    return `${firstChar}${masked}@${domain}`;
  }

  private maskPhone(phone: string): string {
    phone = phone.replace(/[\s\(\)\-]/g, '');

    if (phone.length < 8) {
      return phone;
    }

    const ddd = phone.slice(0, 2);
    const firstPart = phone.slice(2, 4);
    const lastPart = phone.slice(-2);
    const masked = '*'.repeat(phone.length - 6);

    return `(${ddd}) ${firstPart}${masked}${lastPart}`;
  }
}
