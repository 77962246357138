<form *ngIf="formGroup" [formGroup]="formGroup">
  <table mat-table [dataSource]="dataSource" class="fd-table">
    <!-- ID Column -->
    <ng-container matColumnDef="billingRange" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef class="fd-table__header">Faturamento</th>
      <td mat-cell *matCellDef="let row">{{ row.billingRange }}</td>
    </ng-container>

    <!-- Name Column -->
    <ng-container matColumnDef="suggestedPrepaymentFee" class="fd-table__cell">
      <th mat-header-cell *matHeaderCellDef mat-sort-header class="fd-table__header">Taxa sugerida</th>
      <td mat-cell *matCellDef="let row">
        <div class="fd-table__form-container">
          <fd-fee-editor
            (openDialogFn)="openPrepaymentFeeEditorModal($event)"
            [parentForm]="formGroup"
            [field]="fields.suggestedPrepaymentFee"
          ></fd-fee-editor>

          <ng-container *ngIf="shouldShowAlert()">
            <fa-icon
              matTooltipClass="fd-input__tooltip-dialog"
              class="tooltip-icon"
              icon="info-circle"
              size="1x"
              mat-button
              [matTooltip]="UNDER_MINIMUM_LIMIT_TEXT"
              #tooltip="matTooltip"
              (click)="showTooltipAndStopPropagation()"
            >
            </fa-icon>
          </ng-container>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row class="fd-table__header-row" *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row class="fd-table__row" *matRowDef="let row; columns: displayedColumns"></tr>
  </table>
</form>
