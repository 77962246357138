import { AfterViewInit, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { MatTooltip } from '@angular/material/tooltip';
import { take, takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { FeeTypeEnum } from 'src/app/order/enums/fee-type.enum';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { ClientService } from 'src/app/order/services/external/client-validation/client-validation.service';
import { FeeService } from 'src/app/order/services/external/fee/fee.service';
import { ProposalDraftService } from 'src/app/order/services/external/proposal-draft/proposal-draft.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { SimulationService } from 'src/app/order/services/external/simulation/simulation.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { Proposal } from 'src/app/shared/models/proposal';
import { ProposalDraftQueryModel } from 'src/app/shared/models/proposal-draft-query.model';
import { Rate, RatesClientValidation } from 'src/app/shared/models/rates-client-validation.model';
import { MdrFeeModel } from 'src/app/shared/models/response/response-mdr-fee.model';
import { PrepaymentFeeModel } from 'src/app/shared/models/response/response-prepayment-fee.model';
import { SimulationFeesModel, SimulationModel, SimulationServicesModel } from 'src/app/shared/models/simulation.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { FeeEditorModalComponent } from '../../modals/fee-editor-modal/fee-editor-modal.component';
import { ValueEditorDialogData } from '../../modals/value-editor-modal/value-editor-modal.component';
import { PrepaymentTableComponent } from './prepayment-table/prepayment-table.component';

@Component({
  selector: 'app-mdr-fee-edit',
  templateUrl: './mdr-fee-edit.component.html',
  styleUrls: ['./mdr-fee-edit.component.scss'],
})
export class MdrFeeEditComponent extends FormStep implements OnInit, OnDestroy, AfterViewInit {
  public STEP_NUMBER = 0;
  public readonly UNDER_MINIMUM_LIMIT_TEXT =
    'Devido ao fato da taxa informada ser menor que a taxa mínima, a proposta poderá não ser submetida ou aprovada';
  public configuration: ConfigurationModel;
  public dialogClosed: boolean = true;
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public fieldsArray: FdFieldConfigs[] = new Array<FdFieldConfigs>();
  public alive = true;
  public proposal: Proposal;
  public userData: UserDataModel;
  public tableInterval: number;
  public isCEF: boolean = this.dataStoreService.getCEFFlag();
  public displayedColumnsLotteric = ['flag', 'transaction', 'fee', 'tariffValue'];
  public displayedColumns = ['flag', 'transaction', 'fee'];
  public mdrFees: MdrFeeModel[];
  public proposalMdrModel: MdrFeeModel[];
  public dataSource: MatTableDataSource<MdrFeeModel> = new MatTableDataSource();

  public hasErrorGetRatesMdr: boolean = false;

  @Output()
  public backAction: EventEmitter<void> = new EventEmitter();

  @ViewChild('tooltip') public tooltip: MatTooltip;

  @ViewChild('row') public row: ElementRef;

  @ViewChild(PrepaymentTableComponent)
  public prepaymentComponent: PrepaymentTableComponent;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private clientService: ClientService,
    private feeService: FeeService,
    private proposalDraftService: ProposalDraftService,
    private simulationService: SimulationService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    super.ngOnInit();
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public ngAfterViewInit() {
    this.createFormGroup();
  }

  public get isPrepaymentEnabled(): boolean {
    return (
      this.proposal &&
      this.proposal.feeTypeModel &&
      this.proposal.feeTypeModel.enablePrepayment &&
      this.configuration &&
      this.configuration.ableAnticipation
    );
  }

  public get isAnticipationExternal(): boolean {
    return this.proposal && this.proposal.feeTypeModel && this.proposal.feeTypeModel.anticipationExternal;
  }

  public get isCnaeLoterica(): boolean {
    return this.proposal.offerDataDetails.cnae.toString() === '8299706';
  }

  public get formControls(): { [key: string]: AbstractControl } {
    return this.formGroup.controls;
  }

  private get feeFormsArray(): FormArray {
    return this.formControls.feeForms as FormArray;
  }

  public get feeFormsControls() {
    return this.feeFormsArray.controls as FormGroup[];
  }

  public get isCPF() {
    return (
      this.proposal &&
      this.proposal.registrationDataPersonalInfo &&
      this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF
    );
  }

  public get hasSplitTech(): boolean {
    return this.proposal.productSelection.hasSplitTech;
  }

  public createFields(): FdFieldConfigs {
    if (this.isPrepaymentEnabled) {
      return {
        ...this.prepaymentComponent.getFields,
        suggestedMdrFee: {
          controlName: 'suggestedMdrFee',
          maskCharsReplace: /[% ]/,
          label: 'Valor unitário',
          modalTitle: 'Indique a taxa do produto',
          childControl: {
            controlName: 'feeEdit',
            label: 'Taxa solicitada',
            isPercent: true,
            maxLength: 4,
            messages: {
              required: 'Informe o valor',
              invalid: 'Valor inválido',
              valueTooLow: 'O valor deve ser maior que o mínimo especificado',
              valueTooHigh: 'O valor deve ser menor que o máximo especificado',
            },
          },
        },
      };
    }
    return {
      suggestedMdrFee: {
        controlName: 'suggestedMdrFee',
        maskCharsReplace: /[% ]/,
        label: 'Valor unitário',
        modalTitle: 'Indique a taxa do produto',
        childControl: {
          controlName: 'feeEdit',
          label: 'Taxa solicitada',
          isPercent: true,
          maxLength: 4,
          messages: {
            required: 'Informe o valor',
            invalid: 'Valor inválido',
            valueTooLow: 'O valor deve ser maior que o mínimo especificado',
            valueTooHigh: 'O valor deve ser menor que o máximo especificado',
          },
        },
      },
    };
  }

  public createFormGroup(): FormGroup {
    if (this.isPrepaymentEnabled) {
      return this.formBuilder.group({
        prepaymentForm: this.prepaymentComponent.getFormGroup(),
        feeForms: this.formBuilder.array([]),
      });
    }
    return this.formBuilder.group({
      feeForms: this.formBuilder.array([]),
    });
  }

  public async persistData() {
    const rawValue = this.formGroup.getRawValue();

    if (this.isPrepaymentEnabled) {
      return await this.dataStore.updateProposal({
        prepaymentFeeModel: rawValue.prepaymentForm,
        mdrFeeModel: rawValue.feeForms,
      });
    }

    // Caso ocorra algum erro ao buscar taxas MDR, o objeto de proposta nao poderá ser atualizado
    if (this.hasErrorGetRatesMdr) {
      return;
    }

    return await this.dataStore.updateProposal({ mdrFeeModel: rawValue.feeForms });
  }

  public getFormControlByIndex(index: number) {
    const formArray = this.feeFormsArray;
    return (formArray.controls[index] as FormGroup).controls;
  }
  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
  }

  public shouldShowAlert(i: number) {
    const formGroup = this.feeFormsControls[i];
    return (
      this.dialogClosed &&
      formGroup &&
      formGroup.controls &&
      formGroup.controls.feeEdit &&
      formGroup.controls.feeEdit.value !== null &&
      formGroup.controls.feeEdit.value !== undefined &&
      formGroup.controls.feeEdit.value !== '' &&
      formGroup.controls.minimumMdrFee &&
      formGroup.controls.feeEdit.value < formGroup.controls.minimumMdrFee.value
    );
  }

  public onModalConfirm(data: ValueEditorDialogData) {
    data.formControl.setValue(data.formGroup.controls.feeEdit.value);
  }

  public openFeeEditorModal(dialogData: ValueEditorDialogData, feeIndex: number) {
    if (this.hasSplitTech) {
      return;
    }

    const relatedFormGroup = this.feeFormsArray.controls[feeIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.suggestedMdrFee !== null && rawValue.suggestedMdrFee !== undefined) {
        const item = this.mdrFees.find((val, idx, arr) => idx === feeIndex);

        if (item) {
          dialogData.title = dialogData.field.modalTitle;
          dialogData.itemDescription = `${item.flag} - ${item.transaction}`;
          dialogData.maxValue = item.suggestedMdrFee;
          dialogData.initialValue = dialogData.formControl.value || item.suggestedMdrFee;
          dialogData.minValue = item.minimumMdrFee;
          dialogData.confirmCallbackFn = this.onModalConfirm.bind(this);

          const dialogRef = this.dialog.open(FeeEditorModalComponent, {
            disableClose: true,
            width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
            data: dialogData,
          });

          dialogRef.afterClosed().subscribe((_) => {
            this.dialogClosed = true;
          });

          dialogRef.afterOpened().subscribe((_) => {
            this.dialogClosed = false;
          });

          return;
        }
      }
    }
  }

  public addFeeForm(fee: Partial<MdrFeeModel> = {}) {
    this.fieldsArray.push(this.createFields());
    this.feeFormsArray.push(this.createFeeForm(fee));
  }

  public processMdrFees(mdrFeeModels: MdrFeeModel[]) {
    mdrFeeModels.forEach((item) => {
      if (item.feeType == 'CURRENCY' && this.isCnaeLoterica) {
        item.tariffValue = item.suggestedMdrFee;
      }
      this.addFeeForm(item);
    });
    this.dataSource.data = mdrFeeModels;
  }

  public saveSimulation() {
    if (!this.isFormGroupValid()) {
      return;
    }

    this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
    this.persistData().then((persisted) => {
      if (persisted) {
        this.dataStore
          .getProposal()
          .pipe(take(1))
          .pipe(takeWhile(() => this.alive))
          .toPromise()
          .then(
            (proposal) => {
              this.proposal = proposal;
              if (this.dataStoreService.isBinServiceContractProposal(this.proposal.serviceContract)) {
                this.validateRates();
              } else {
                this.submitSimulation();
              }
            },
            (error) => {}
          );
      }
    });
  }

  public buildFeeObject(): SimulationFeesModel[] {
    return this.proposal.mdrFeeModel.map(
      (item) =>
        ({
          idService: item.serviceId && item.serviceId.toString(),
          minimumMdrFee: item.minimumMdrFee,
          sentMdrFee: item.feeEdit,
          suggestedMdrFee: item.suggestedMdrFee,
          modality: item.modality,
          transactionDescription: item.transaction,
        } as SimulationFeesModel)
    );
  }

  public buildServiceObject(): SimulationServicesModel[] {
    return this.proposal.productSelection.products.map(
      (item) =>
        ({
          idService: item.technology,
          technologyAmount: item.amount,
          technologyPrice: Number(item.price.toString().replace(/[Rr$\s]/g, '')),
        } as SimulationServicesModel)
    );
  }

  public deleteDraft() {
    const activeDraft = localStorage.getItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

    this.proposalDraftService
      .deleteDraft(this.createRequestObject(activeDraft))

      .subscribe(
        (_) => {
          this.dialogService.openDialog(Messages.SIMULATION_SAVE_SUCCESS, () => this.routingService.navigateToHome());
        },
        (error) => {
          let errorArg = error;
          if (error.error && typeof error.error === 'object') {
            errorArg = error.error;

            if (errorArg.field) {
              errorArg = errorArg.field;
            } else if (error.error instanceof ProgressEvent) {
              errorArg = error.message;
            }
          }
          this.dialogService.openErrorDialog(Messages.SIMULATION_SAVE_ERROR, errorArg, () => this.routingService.navigateToHome());
        }
      );
  }

  public createRequestObject(externalDraftUuid): ProposalDraftQueryModel {
    return {
      externalDraftUuid,
    };
  }

  public submitSimulation() {
    const userData = this.dataStoreService.getUserData();

    if (!userData) {
      return;
    }

    const model: SimulationModel = {
      agentCpfCnpj: userData.cpfCnpj,
      billingValue: this.proposal.offerDataDetails.annualRevenue,
      cnaeNumber: Number(this.proposal.offerDataDetails.cnae),
      cpfCnpj: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      email: this.isCPF ? this.proposal.registrationDataPersonalInfo.personEmail : this.proposal.registrationDataPersonalInfo.companyEmail,
      fees: this.buildFeeObject(),
      services: this.buildServiceObject(),
    };

    if (model && this.isPrepaymentEnabled) {
      model.suggestedPrepaymentFee =
        this.isPrepaymentEnabled && this.proposal.prepaymentFeeModel
          ? (this.proposal.prepaymentFeeModel as any).suggestedPrepaymentFee
          : null;
      model.sentPrepaymentFee =
        this.isPrepaymentEnabled && this.proposal.prepaymentFeeModel ? (this.proposal.prepaymentFeeModel as any).feeEdit : null;
    }

    this.simulationService.saveSimulation(model).subscribe(
      (_) => {
        this.deleteDraft();
      },
      (error) => {
        let errorArg = error;
        if (error.error && typeof error.error === 'object') {
          errorArg = error.error;

          if (errorArg.field) {
            errorArg = errorArg.field;
          } else if (error.error instanceof ProgressEvent) {
            errorArg = error.message;
          }
        }
        this.dialogService.openErrorDialog(Messages.SIMULATION_SAVE_ERROR, errorArg, () => this.routingService.navigateToHome());
      }
    );
  }

  public next(): void {
    this.submitted = true;
    if (this.dataStoreService.isBinServiceContractProposal(this.proposal.serviceContract)) {
      this.validateRates();
    } else {
      super.next();
    }
  }

  public validateRates() {
    const data: RatesClientValidation = this.buildValidationRatesData();
    this.clientService.validateRates(data).subscribe(
      (response) => {
        if (!response) {
          this.dialogService.openDialog(Messages.RATES_INVALID_SIPAG);
        } else {
          this.isCEF ? this.submitSimulation() : this.nextPage();
        }
      },
      (err) => {
        this.erroValidateRates(err);
      }
    );
  }

  public buildValidationRatesData(): RatesClientValidation {
    const ratesList: Rate[] = new Array();
    const rawValue = this.formGroup.getRawValue();
    const mdrFeeModel: MdrFeeModel[] = rawValue.feeForms;

    mdrFeeModel.forEach((mdr) => {
      const rate: Rate = {
        acquiringServiceId: mdr.serviceId.toString(),
        codeFlag: mdr.codeFlag,
        modality: mdr.modality,
        rate: mdr.feeEdit ? mdr.feeEdit.toString() : mdr.suggestedMdrFee.toString(),
      };
      ratesList.push(rate);
    });

    const data: RatesClientValidation = {
      cnae: this.proposal.offerDataDetails.cnae,
      document: this.proposal.registrationDataPersonalInfo.cpfCnpj,
      rateCategory: FeeTypeEnum.MDR,
      subChannel: this.proposal.subChannel,
      ratesList,
    };
    return data;
  }

  public async updateFields(proposal: Proposal) {
    this.proposalMdrModel = proposal.mdrFeeModel;
    let mdrErro = true;
    let prepaymentErro = true;
    let isEnablePrepayment = false;
    let isAnticipationExternal = false;
    if (proposal.feeTypeModel) {
      isEnablePrepayment = proposal.feeTypeModel.enablePrepayment ?? false;
      isAnticipationExternal = proposal.feeTypeModel.anticipationExternal ?? false;
    }
    try {
      const responseMdrFees = await this.feeService
        .getMdrFees(
          this.proposal.offerDataDetails.cnae,
          this.proposal.registrationDataAddress.state,
          this.proposal.offerDataDetails.campaign,
          this.proposal.offerDataDetails.revenueRange,
          this.proposal.registrationDataPersonalInfo.documentCategory === 'CPF' ? 'P' : 'L',
          isEnablePrepayment
        )
        .toPromise();

      this.processMdrFees(!!this.proposalMdrModel ? this.proposalMdrModel : responseMdrFees);
      this.mdrFees = responseMdrFees;

      mdrErro = false;
      if (isEnablePrepayment) {
        const responsePrepayment = await this.feeService
          .getPrepaymentFee(
            proposal.offerDataDetails.cnae,
            proposal.offerDataDetails.revenueRange,
            proposal.offerDataDetails.campaign,
            isAnticipationExternal
          )
          .toPromise();
        this.prepaymentComponent.updatePrepayment(responsePrepayment, proposal);
      }

      prepaymentErro = false;
    } catch (err) {
      this.hasErrorGetRatesMdr = true;
      const serializedError = err.error ? (err.error as ApiResultModel) : null;
      this.erroMdr(serializedError, mdrErro, prepaymentErro);
    }
  }

  protected proposalGetCallback(): void {
    this.userData = this.dataStoreService.getUserData();

    if (!this.proposal || !this.proposal.offerDataDetails || !this.proposal.feeTypeModel) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Taxas MDR',
        () => this.back(),
        null,
        'error'
      );
    }

    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
  }

  public back(saveData: boolean = true): void {
    if (this.isFormGroupValid() && saveData) {
      this.persistData().then((persisted) => {
        if (persisted) {
          this.wizardService.previousStep();
        }
      });
    } else {
      this.wizardService.previousStep();
    }
  }

  private createFeeForm(product: Partial<MdrFeeModel>): FormGroup {
    return this.formBuilder.group({
      suggestedMdrFee: [product.suggestedMdrFee],
      feeEdit: [product.feeEdit],
      codeFlag: [product.codeFlag],
      flag: [product.flag],
      minimumMdrFee: [product.minimumMdrFee],
      transaction: [product.transaction],
      modality: [product.modality],
      serviceId: [product.serviceId],
      feeType: [product.feeType],
    });
  }

  private nextPage(): void {
    this.submitted = true;

    if (this.isFormGroupValid()) {
      this.dataStore.setStepStatus(this.STEP_NUMBER, this.formGroup.valid);
      this.persistData()
        .then((persisted) => {
          if (persisted) {
            this.wizardService.nextStep();
          }
        })
        .finally(() => {});
    }
  }

  private erroValidateRates(err: any) {
    let message = '';
    let obj: any = err.error.details;
    if (err.error && err.error.details) {
      if (obj instanceof Object) {
        const details = err.error.details;
        if (details && details.mensagem) {
          message += details.mensagem;
          obj = '';
        } else {
          message += JSON.stringify(err.error.details);
        }
      } else {
        message += obj;
      }
    } else if (err.error && err.error.message) {
      message += err.error.message;
    }

    if (message.length > 0) {
      if (obj instanceof Object) {
        if (this.isCEF) {
          this.dialogService.openErrorDialog(Messages.GENERIC_ERROR, JSON.stringify(err.error.details), () =>
            this.routingService.navigateToHome()
          );
          return;
        }
        this.dialogService.openErrorDialog(Messages.GENERIC_ERROR, JSON.stringify(err.error.details));
      } else {
        if (this.isCEF) {
          this.dialogService.openDialogWithMessage(message, () => this.routingService.navigateToHome());
          return;
        }
        this.dialogService.openDialogWithMessage(message);
      }
    } else {
      this.dialogService.openDialog(Messages.GENERAL_ERROR);
    }
  }

  private erroMdr(serializedError: ApiResultModel, mdrErro: boolean, prepaymentErro: boolean) {
    if (serializedError && serializedError.message) {
      if (this.isCEF) {
        this.dialogService.openDialogWithMessage(serializedError.message, () => this.routingService.navigateToHome());
      } else {
        this.dialogService.openDialogWithMessage(serializedError.message, () => this.back(false));
      }
    } else {
      if (this.isCEF) {
        if (mdrErro) {
          this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FEES, () => this.routingService.navigateToHome());
        }

        if (prepaymentErro) {
          this.dialogService.openDialog(Messages.FAILED_TO_GET_PREPAYMENT_FEE, () => this.routingService.navigateToHome());
        }
      } else {
        if (mdrErro) {
          this.dialogService.openDialog(Messages.FAILED_TO_GET_MDR_FEES, () => this.back(false));
        }

        if (prepaymentErro) {
          this.dialogService.openDialog(Messages.FAILED_TO_GET_PREPAYMENT_FEE, () => this.backAction.emit());
        }
      }
    }
  }
}
