import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LoginModel } from 'src/app/shared/models/login.model';
import { TokenInformationModel } from 'src/app/shared/models/response/auth-service.model';
import { CheckOtpModel, TokenModel } from 'src/app/shared/models/response/token.model';
import { environment } from 'src/environments/environment.local';

@Injectable({
  providedIn: 'root',
})
export class LoginService {
  constructor(private http: HttpClient) {}

  checkOtp(data: LoginModel): Observable<CheckOtpModel> {
    return this.http.post<CheckOtpModel>(`${environment.apiUrls.authentication}/check-otp`, data);
  }

  sendOtpToken(data: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${environment.apiUrls.authentication}/send-otp-token`, data);
  }

  public login(data: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${environment.apiUrls.authentication}/login`, data);
  }

  public loginMFA(data: LoginModel): Observable<TokenModel> {
    return this.http.post<TokenModel>(`${environment.apiUrls.authentication}/login/otp`, data);
  }
}
