export interface MessageModel {
  title: string;
  description: string;
}

export class Messages implements MessageModel {
  public static readonly BACK_TO_HOME_WITH_DRAFT_CONFIRMATION = new Messages(
    'Atenção',
    'Você está retornando para a página principal. Deseja salvar o rascunho?'
  );
  public static readonly BACK_TO_HOME_CONFIRMATION = new Messages('Atenção', 'Deseja realmente retornar para a página inicial?');
  public static readonly GENERAL_ERROR = new Messages('Atenção', 'Houve um erro ao processar sua solicitação. Tente novamente mais tarde.');

  public static readonly SERPRO_CNPJ_INFORMATION_ERROR = new Messages('Atenção', 'Erro interno ao consultar Serpro.');
  public static readonly CPF_CNPJ_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este CPF/CNPJ para a oferta!', '');
  public static readonly INVALID_CEP = new Messages('Atenção', 'CEP inválido.');
  public static readonly SERPRO_VALIDATION_ERROR = new Messages(
    'Atenção',
    'Não foi possível validar seus dados. Tente novamente mais tarde.'
  );
  public static readonly BANK_VALIDATION_ERROR = new Messages(
    'Atenção',
    'Não foi possível validar seus dados. Tente novamente mais tarde.'
  );
  public static readonly INVALID_MINIMUM_AGE = new Messages(
    'Atenção',
    'É necessário ser maior de 18 anos para prosseguir no credenciamento.'
  );
  public static readonly INVALID_MINIMUM_AGE_FOR_PARTNER = new Messages(
    'Atenção',
    'É necessário que o sócio seja maior de 18 anos para prosseguir no credenciamento.'
  );
  public static readonly INVALID_BANK_DEBIT = new Messages(
    'Atenção',
    'Dados bancários de débito inválidos. Verifique a agência e conta e tente novamente.'
  );
  public static readonly INVALID_BANK_CREDIT = new Messages(
    'Atenção',
    'Dados bancários de crédito inválidos. Verifique a agência e conta e tente novamente.'
  );
  public static readonly INVALID_CPF_CNPJ = new Messages('Atenção', 'CNPJ/CPF nulo na receita federal. Por favor, corrigir a informação.');
  public static readonly MAX_DOCUMENT_CALL_LIMIT_EXCEEDED = new Messages(
    'Atenção',
    'Tentativas de consulta de CPF/CNPJ esgotadas. Por favor, inicie um novo credenciamento.'
  );
  public static readonly COMMUNICATION_SERPRO_ERROR = new Messages('Atenção', 'Erro de comunicação ao consultar Serpro.');
  public static readonly INTERNAL_SERPRO_ERROR = new Messages('Atenção', 'Erro de comunicação ao consultar Serpro.');
  public static readonly unexpected_serpro_error = new Messages('Atenção', 'Erro inesperado ao consultar Serpro.');
  public static readonly FAILED_TO_GET_TECHNOLOGIES = new Messages(
    'Atenção',
    'Não foi possível carregar as tecnologias. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_GET_PROPOSAL_DATA = new Messages(
    'Indisponibilidade',
    'Serviço indisponível. Após 30 minutos tente novamente.'
  );
  public static readonly NOT_FOUND_MCC_FOR_CNAE = new Messages('Atenção', 'Não existem taxas para a campanha, range e CNAE escolhido.');
  public static readonly MAX_PRODUCT_LIMIT_REACHED = new Messages('Atenção', 'A quantidade máxima de produtos foi atingida.');
  public static readonly DUPLICATED_PRODUCTS = new Messages('Atenção', 'Não é possível enviar produtos duplicados.');
  public static readonly COM_AND_OTHERS_IN_THE_SAME_PROPOSAL = new Messages(
    'Atenção',
    'Não é possível enviar uma tecnologia E-Commerce junto com outro produto de Cartão Presente na mesma proposta.'
  );
  public static readonly MAX_PORCENT_LIMIT_REACHED = new Messages(
    'Atenção',
    'A soma da porcentagem de participação dos sócios/proprietários não pode ser superior a 100%.'
  );
  public static readonly MAX_PARTNER_LIMIT_REACHED = new Messages('Atenção', 'Não é possível adicionar mais sócios.');
  public static readonly UNABLE_TO_OPEN_PRICE_EDIT_DIALOG = new Messages('Atenção', 'Selecione uma tecnologia.');
  public static readonly DUPLICATED_PARTNER_CPF = new Messages('Atenção', `Não podem existir CPF's duplicados para os sócios.`);
  public static readonly DUPLICATED_PARTNER_CNPJ = new Messages('Atenção', `Não podem existir CNPJ's duplicados para os sócios.`);
  public static readonly DUPLICATED_BRANCH_CNPJ = new Messages('Atenção', `Não podem existir CNPJ's duplicados para as filiais.`);
  public static readonly FAILED_TO_GET_RANGE = new Messages(
    'Atenção',
    'Não foi possível carregar o range de preço. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_GET_MDR_FEES = new Messages(
    'Atenção',
    'Não foi possível carregar as taxas MDR. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_GET_MDR_FLEX_FEES = new Messages(
    'Atenção',
    'Não foi possível carregar as taxas MDR Flex. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_GET_MDR_FLEX_FACTORS = new Messages(
    'Atenção',
    'Não foi possível carregar os fatores das taxas MDR Flex. Tente novamente mais tarde.'
  );
  public static readonly NO_FACTORS_AVAILABLE = new Messages('Atenção', 'Não foram encontrados os fatores das taxas MDR Flex.');
  public static readonly FAILED_TO_GET_PREPAYMENT_FEE = new Messages(
    'Atenção',
    'Não foi possível carregar a taxa de antecipação. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_CALCULATE_FEES = new Messages(
    'Atenção',
    'Não foi possível calcular as taxas. Tente novamente mais tarde.'
  );
  public static readonly FAILED_TO_VALIDATE_BANK_DATA = new Messages(
    'Atenção',
    'Não foi possível validar seus dados bancários. Tente novamente mais tarde.'
  );
  public static readonly PROPOSAL_SUBMIT_ERROR = new Messages('Atenção', 'Não foi possível enviar a proposta. Tente novamente mais tarde.');
  public static readonly PROPOSAL_SUBMIT_ERROR_WITH_DETAILS_BUTTON = new Messages(
    'Atenção',
    'Não foi possível enviar a proposta. Clique no botão abaixo para mais detalhes.'
  );
  public static readonly CAMPAIGN_NOT_FOUND = new Messages('Atenção', 'Erro ao obter lista de campanhas. Tente novamente mais tarde.');
  public static readonly PAYMENT_SUBMIT_ERROR = new Messages('Atenção', 'Não foi possível enviar o pagamento. Tente novamente mais tarde.');
  public static readonly SERVICE_CONTRACT_CONFIG_NOT_SET = new Messages(
    'Atenção',
    'Este service contract não está devidamente configurado para efetuar o credenciamento,'
  );
  public static readonly MDR_OR_MDR_FLEX_CONFIG_NOT_SET = new Messages(
    'Atenção',
    'Este service contract não possui nenhum modelo de cobrança (MDR ou MDR Flex) habilitado.'
  );

  public static readonly FAILED_TO_GET_BANK_LIST = new Messages(
    'Indisponibilidade',
    'Serviço indisponível. Após 30 minutos tente novamente.'
  );
  public static readonly SELECT_ACCOUNT_TYPE = new Messages('Atenção', 'Selecione o tipo de conta.');
  public static readonly LOGIN_ERROR = new Messages('Atenção', 'Não foi possível efetuar login. Tente novamente mais tarde.');
  static readonly LOGIN_ERROR_INVALID_SENDBY_OPTION = new Messages('Atenção', 'Escolha ao menos uma opção para o envio do token.');
  public static readonly LOGIN_ERROR_INVALID_CORRELATIONID = new Messages(
    'Atenção',
    'Falha ao obter a referencia do token. Contate o administrador.'
  );
  public static readonly LOGIN_SEND_TOKEN_SMS = new Messages('Atenção', 'Token enviado com sucesso. Verifique seu celular');
  public static readonly LOGIN_SEND_TOKEN_EMAIL = new Messages('Atenção', 'Token enviado com sucesso. Verifique seu email');

  public static readonly LOGIN_ERROR_UNAVAILABLE_BACKEND = new Messages(
    'Atenção',
    'Não foi possível efetuar login. Serviço indisponível: Credenciamento - Backend'
  );
  public static readonly NO_TOKEN_DATA = new Messages('Atenção', 'Não foi possível efetuar login. Tente novamente mais tarde.');
  public static readonly NO_LOGIN_DATA = new Messages('Atenção', 'Insira o usuário e senha para continuar.');
  public static readonly NO_TECHNOLOGIES_FOUND = new Messages('Atenção', 'Nenhuma tecnologia disponível.');
  public static readonly SESSION_TIMED_OUT = new Messages('Atenção', 'Sessão expirada. Realize o login e tente novamente.');
  public static readonly UNWANTED_CNAE_SELECTED = new Messages('Atenção', 'Cliente com CNAE Indesejado.');

  public static readonly MISSING_ECOMMERCE_SALES_CHANNEL = new Messages('Atenção', 'É necessário selecionar ao menos um canal de venda.');
  public static readonly MISSING_FUNCTIONALITY = new Messages('Atenção', 'É necessário selecionar ao menos uma funcionalidade.');
  public static readonly MISSING_PROSPECTION_TYPE = new Messages(
    'Atenção',
    'É necessário selecionar ao menos uma opção relacionada a oportunidade de negócio.'
  );
  public static readonly FEES_LOWER_THAN_ALLOWED = new Messages(
    'Atenção',
    'Os valores de taxas selecionadas na proposta não são permitidas. Por gentileza, ajuste os valores e envie a proposta novamente.'
  );
  public static readonly TECHNOLOGIES_PRICES_LOWER_THAN_ALLOWED = new Messages(
    'Atenção',
    'Os valores de aluguel/venda presentes na proposta não são permitidos. Por gentileza, ajuste os valores e envie a proposta novamente.'
  );
  public static readonly MISSING_TEF_CONNECTION_TYPE = new Messages('Atenção', 'É necessário selecionar ao menos um tipo de conexão.');
  public static readonly MISSING_TEF_PROVIDER_TYPE = new Messages('Atenção', 'É necessário selecionar ao menos um provedor.');
  public static readonly ALGAR_CHIP_NOT_FOUND = new Messages('Atenção', 'Chip Algar não encontrado.');
  public static readonly ONLY_ECOMMERCE_PROPOSAL = new Messages(
    'Atenção',
    'Só é possível prosseguir com a criação de uma proposta E-Commerce. Deseja seguir?'
  );
  public static readonly ERROR_WHEN_FILLING_POS_AMOUNT = new Messages(
    'Atenção',
    'Erro ao obter quantidade de tecnologias. Tente novamente mais tarde.'
  );
  public static readonly ERROR_WHEN_VALIDATING_POS_AMOUNT = new Messages(
    'Atenção',
    'Erro ao validar quantidade de tecnologias. Tente novamente mais tarde.'
  );
  public static readonly UNABLE_TO_PROCEED_POS_AMOUNT = new Messages(
    'Atenção',
    'Não é possível prosseguir para este cliente com a quantidade de produtos POS selecionada.'
  );
  public static readonly NO_POS_AMOUNT = new Messages('Atenção', 'Não há tecnologias POS disponíveis para este cliente.');
  public static readonly PURCHASE_DATE_GREATER_THAN_TODAY = new Messages('Atenção', 'Data de emissão é superior à data atual.');
  public static readonly PURCHASE_DATE_GREATER_THAN_90_DAYS = new Messages(
    'Atenção',
    'Data de emissão da nota maior que 90 dias. Não é possível prosseguir com a inclusão.'
  );
  public static readonly INVOICE_NUMBER_EXIST = new Messages('Atenção', 'Número de nota fiscal já incluída.');
  public static readonly INVOICE_NUMBER_EMPTY = new Messages('Atenção', 'Informe o número de nota fiscal.');
  public static readonly INVOICE_PRODUCT_EMPTY = new Messages('Atenção', 'Informe o tipo de produto da nota fiscal.');
  public static readonly MAX_INVOICES = new Messages('Atenção', 'A proposta deverá conter no máximo 3 notas fiscais.');
  public static readonly MAX_TOTAL_VALUE_INVOICES = new Messages('Atenção', 'O somatório das notas deverá ser maior ou igual a R$ 400,00.');
  public static readonly INVOICE_VALUE_ZERO = new Messages('Atenção', 'O valor da nota fiscal deverá ser maior que zero.');
  public static readonly CONFIG_PROFESSIONAL_LOAD_FAIL = new Messages(
    'Atenção',
    'Não foi possível carregar as opções de LICENÇA PROFISSIONAL.'
  );
  public static readonly CONFIG_PROPOSAL_LOAD_FAIL = new Messages('Atenção', 'Falha ao carregar as configurações das propostas.');
  public static readonly ANNUAL_REVENUE_MIN = new Messages('Atenção', 'Valor de faturamento anual deverá ser maior que um.');
  public static readonly ANNUAL_REVENUE_INVALID = new Messages(
    'Atenção',
    'Valor de faturamento anual não confere com o a faixa escolhida.'
  );
  public static readonly AVERANGE_TICKET = new Messages('Atenção', 'Valor de ticket médio deverá ser maior que um.');
  public static readonly ANNUAL_VOLUME_SALES_CARD_GROUP = new Messages(
    'Atenção',
    'Valor do faturamento total de cartão do Grupo deverá ser maior que um.'
  );
  public static readonly AVERANGE_TICKET_BIGGER_THAN_ANNUAL_REVENUE = new Messages(
    'Atenção',
    'Valor de ticket médio deverá ser menor que o faturamento anual.'
  );

  public static readonly TYPE_SERVICE_INVALID_SIPAG = new Messages(
    'Atenção',
    'Cadastro já existente com mesma tecnologia! Não é possível prosseguir com o credenciamento.'
  );
  public static readonly RATES_INVALID_SIPAG = new Messages(
    'Atenção',
    'Cadastro cliente Sipag já existente com taxa menor/maior que a informada! Não é possível prosseguir com o credenciamento.'
  );
  public static readonly CPF_CNPJ_NOT_AUTHORIZED_CHANNEL_SIPAG = new Messages(
    'Não é possível prosseguir com este CPF/CNPJ para a oferta pela validação por canal!',
    ''
  );
  public static readonly INVALID_INVOICE_DATE = new Messages('Atenção', 'Data de emissão da nota inválida.');
  public static readonly INVALID_TEF_DATE = new Messages('Atenção', 'Data inválida.');
  public static readonly INVALID_MIN_TEF_DATE = new Messages('Atenção', 'A data inicial deve ser maior ou igual a data atual.');
  public static readonly INVALID_MAX_TEF_DATE = new Messages('Atenção', 'A data final deve ser maior ou igual a data atual.');
  public static readonly INVALID_MAX_AND_MIN_TEF_DATE = new Messages('Atenção', 'A data inicial deve ser menor que a data final.');
  public static readonly PREPAYMENT_CHECK_ERROR = new Messages('Atenção', 'Erro ao realizar validação para taxas para antecipação.');
  public static readonly PREPAYMENT_VALIDATION_NOT_FOUND_RATE = new Messages(
    'Atenção',
    'Não foi encontrado taxa de antecipação para o MCC do CNAE informado, campanha e range de faturamento selecionados.'
  );
  public static readonly DRAFT_SAVE_ERROR = new Messages(
    'Atenção',
    'Ocorreu um erro ao salvar o rascunho da sua proposta. Aguarde alguns segundos e tente prosseguir novamente.'
  );
  public static readonly DRAFT_LIMIT_EXCEEDED = new Messages(
    'Atenção',
    'A quantidade máxima de rascunhos foi atingida. Para prosseguir, exclua um dos rascunhos existentes.'
  );
  public static readonly GENERIC_ERROR = new Messages(
    'Atenção',
    'Ocorreu um erro ao processar sua requisição, clique em detalhes para mensagem detalhada do erro.'
  );
  public static readonly OPEN_DATE_LEGAL_PERSON_ERROR = new Messages('Atenção', 'A data de fundação deverá ser maior que a data de hoje.');
  public static readonly DRAFT_DELETE_CONFIRMATION = new Messages(
    'Atenção',
    'Deseja realmente excluir este rascunho? Esta ação não poderá ser desfeita!'
  );
  public static readonly EDIT_SAVE_SUCCESS = new Messages('Atenção', 'Alteração realizada com sucesso.');
  public static readonly EDIT_SAVE_ERROR = new Messages('Atenção', 'Houve um erro ao realizar a alteração.');

  public static readonly PROPOSAL_DUPLICITY_ERROR = new Messages(
    'Atenção',
    'Não será possível seguir com esta proposta. \
    Já existe um credenciamento para este CNPJ.'
  );

  public static readonly PROPOSAL_DUPLICITY_SIMULADOR_ERROR = new Messages(
    'Atenção',
    'Não será possível seguir com esta simulação. \
    Já existe um credenciamento para este CPF/CNPJ.'
  );

  public static readonly ERROR_WHEN_CHECK_PROPOSAL_DUPLICITY = new Messages(
    'Atenção',
    'Erro ao validar duplicidade da proposta. Tente novamente mais tarde.'
  );
  public static readonly WARN_ABOUT_SPLIT_TECHNOLOGY = new Messages(
    'Atenção',
    'Você selecionou uma tecnologia que é de uso exclusivo para o cadastro do EC Seller do serviço de Split de Pagamento.'
  );
  public static readonly ERROR_GET_CHECK_SPLITS_TECH = new Messages(
    'Atenção',
    'Não foi possível carregar validadores de tecnologia splits. Tente novamente mais tarde.'
  );
  public static readonly SPLITS_TECH_MUST_BE_UNIQUE = new Messages(
    'Atenção',
    'Para propostas de split de pagamento não é permitido mais de uma tecnologia.'
  );

  public static readonly INCORRECT_CNAE = new Messages(
    'Atenção',
    // tslint:disable-next-line: quotemark
    "O CNAE informado não corresponde a nenhum dos CNAE's válidos para este CNPJ. \
    Clique no botão abaixo para corrigir a informação."
  );
  public static readonly SIMULATOR_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN = new Messages(
    'Atenção',
    'O simulador não está disponível para a hierarquia associada ao login informado.'
  );
  public static readonly SIMULATION_SAVE_SUCCESS = new Messages('Atenção', 'Simulação salva com sucesso.');
  public static readonly SIMULATION_SAVE_ERROR = new Messages(
    'Atenção',
    'Houve um erro ao salvar a simulação. Tente novamente mais tarde.'
  );
  public static readonly PROPOSAL_SUBMIT_ERROR_CNAE_SERVICE_FAILED = new Messages(
    'Atenção',
    'As configurações do contrato não estão definidas corretamente, solicite ajustes.'
  );

  public static readonly EMAIL_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este e-mail para a oferta!', '');
  public static readonly PHONE_NOT_AUTHORIZED = new Messages('Não é possível prosseguir com este telefone para a oferta!', '');
  public static readonly GENERAL_ERROR_BLACKLIST_API = new Messages(
    'Atenção',
    'Houve um erro ao validar dados na blacklist. Tente novamente mais tarde.'
  );
  public static readonly SIMULATION_SEND_ERROR = new Messages(
    'Atenção',
    'Houve um erro ao enviar a negociação. Tente novamente mais tarde.'
  );

  public static readonly FISERV_ONLINE_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN = new Messages(
    'Atenção',
    'Este recurso não está disponível para o seu usuário. \
    Ao fechar esta mensagem você será redirecionado para o Visualizador de Taxas da Caixa.'
  );

  public static readonly TOTAL_REVENUE_PERCENT = new Messages(
    'Atenção',
    'A distribuição do faturamento por produto não poderá ser diferente de 100%.'
  );
  public static readonly TOTAL_REQUESTED_EXCEPTIONS = new Messages(
    'Atenção',
    'O total de isenções não poderá ser maior que a quantidade de máquinas.'
  );
  public static readonly CNAE_LOTERICAS_VALIDATION = new Messages(
    'Atenção',
    'Para credenciamento de Lotéricas utilize o plano 24 no SICRD.' +
      ' Consulte as condições comerciais no material de apoio, disponível no redevarejo.caixa – Ações Táticas'
  );
  public static readonly INVALID_STRUCTURE_URL = new Messages('Atenção', 'A url informada está com formato inválido!');
  public static readonly EC_MASTER_NOT_FOUND = new Messages('Atenção', 'O EC Master informado não foi encontrado!');

  public static readonly SUBMIT_NOT_EDI = new Messages(
    'Atenção',
    ' Você solicitou uma proposta para envio do Extrato EDI porém não aceitou as condições do TERMO DE USO.\n' +
      'O Extrato EDI não será enviado à Conciliadora.\n' +
      'Deseja aceitar as condições de Termo de Uso agora?'
  );

  public static readonly CHECK_ABLE_PAYMENT_SPLIT = new Messages(
    'Atenção',
    'A tecnologia de split de pagamento não está ativada no momento! Tente novamente mais tarde. '
  );

  public static readonly ABOUT_ACCEPT_TERM_FIDC = new Messages(
    'Atenção',
    // tslint:disable-next-line:max-line-length
    'O cliente solicitou a antecipação automática de recebimentos. Para prosseguir com o credenciamento, confirme se ele aceitou o termo de antecipação.'
  );
  public static readonly MODAL_NOT_CONFIGURED_ACCREDITATION_TYPE = new Messages(
    'Atenção',
    'Tipo de credenciamento precisa ser definido na configuração da proposta'
  );

  public static readonly CNAE_INELEGIBLE = new Messages('Simulação Não Permitida', 'CNAE não elegível ao credenciamento');

  public static readonly OFFER_NOT_FOUND = new Messages(
    'Oferta não encontrada',
    'Não foram encontradas ofertas com as condições informadas'
  );

  public static readonly MODAL_NOT_INTEGRATION = new Messages('Atenção', 'Erro ao buscar informação de agência');

  public static readonly CNAE_NOT_ELEGIBLE_CNP = new Messages('Atenção', 'CNAE não elegível a tecnologia Cartão não Presente.');

  public static readonly COPY_TEXT_SUCCESS = new Messages('Atenção', 'Link copiado para a área de transfência.');

  public title: string;
  public description: string;
  public html?: boolean;

  public constructor(private titleParam: string, private descriptionParam: string, public htmlParam?: boolean) {
    this.title = titleParam;
    this.description = descriptionParam;
    this.html = htmlParam;
  }

  public toString() {
    return this.title;
  }
}
