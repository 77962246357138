interface LoginModelOptions {
  username?: string;
  password?: string;
  correlationId?: string;
  tokenOTP?: string;
  sendBy?: string;
}

export class LoginModel {
  public username: string;
  public password: string;
  correlationId: string;
  tokenOTP: string;
  sendBy: string;

  constructor(options: LoginModelOptions = {}) {
    this.username = options.username;
    this.password = options.password;
    this.correlationId = options.correlationId;
    this.tokenOTP = options.tokenOTP;
    this.sendBy = options.sendBy;
  }
}
