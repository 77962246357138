<div class="fd-accreditation-login" [fxFlex.md]="50" [fxFlex.lg]="75">
  <div class="fd-accreditation-login__title">
    <h6 *ngIf="isCEF">ESTE VISUALIZADOR DE TAXAS FOI DESCONTINUADO.</h6>
    <h6 *ngIf="isCEF">PARA REALIZAR A SIMULAÇÃO PARA NÃO CORRENTISTAS UTILIZE A FUNCIONALIDADE DISPONIBILIZADA NO SICRD.</h6>

    <h4 *ngIf="!isCEF">CREDENCIAMENTO ONLINE</h4>
    <img *ngIf="!isCEF" class="fd-accreditation-login__fiserv-logo" [src]="getLogoPath()" />
  </div>

  <img *ngIf="isCEF" class="fd-accreditation-login__fiserv-logo" style="margin-top: 40px" [src]="getLogoPath()" />

  <form *ngIf="formGroup && !isCEF" [formGroup]="formGroup" (keyup.enter)="login()">
    <div fxLayout="column" fxLayoutAlign="center stretch">
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.user"></fd-input>
      </div>
      <div>
        <fd-input [parentForm]="formGroup" [field]="fields.password"></fd-input>
        <a class="right-text" (click)="getPasswordRecovery()">Esqueci minha senha</a>
      </div>
    </div>

    <p class="otp-option-title" *ngIf="isMFA && !isOptOptionDisabled">Como você deseja receber o token:</p>

    <mat-radio-group
      aria-label="Selecione uma opção"
      formControlName="tokenChannel"
      (change)="isRadioSelected($event)"
      *ngIf="isMFA && !isOptOptionDisabled"
    >
      <div class="fd-radio-button__option-container">
        <mat-radio-button value="EMAIL">{{ userEmail | coverEmailPhone: 'email' }}</mat-radio-button>
      </div>
      <div *ngIf="userPhone" class="fd-radio-button__option-container">
        <mat-radio-button value="SMS">{{ userPhone | coverEmailPhone: 'phone' }}</mat-radio-button>
      </div>
    </mat-radio-group>

    <div class="mfa-token" *ngIf="isMFA && isOptOptionDisabled && radioValue">
      <fd-input [parentForm]="formGroup" [field]="fields.mfaToken"></fd-input>
      <a *ngIf="isOptOptionDisabled && userPhone" class="left-text" (click)="enableOtpOption()">Mudar Opção</a>
      <a *ngIf="loginButtonActive" class="right-text" (click)="reenviarToken()">Reenviar Token</a>
    </div>
  </form>

  <div class="login-button" *ngIf="!isCEF">
    <fd-button fdPrimaryLarge mat-flat-button [disabled]="!loginButtonActive" id="btnLogin" [content]="'Entrar'" (trigger)="login()">
    </fd-button>
  </div>
</div>
