import { HttpErrorResponse } from '@angular/common/http';
import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { takeWhile } from 'rxjs/operators';
import { Constants } from 'src/app/order/constants/constants';
import { cpfMask } from 'src/app/order/masks/document-masks';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { LoginService } from 'src/app/order/services/external/login/login.service';
import { AuthService } from 'src/app/order/services/internal/auth/auth.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { InputType } from 'src/app/shared/fd-form-components/fd-input/fd-input.component';
import { ApiResultModel } from 'src/app/shared/models/api-result.model';
import { LoginModel } from 'src/app/shared/models/login.model';
import { Proposal } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { environment } from 'src/environments/environment.local';
import { startRoutingDefinitions } from '../../routing/start-routing-definitions';
import { Observable } from 'rxjs';
import { TokenModel } from '../../../shared/models/response/token.model';
import { UserDataModel } from 'src/app/shared/models/user-data.model';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  public formGroup: FormGroup;
  public fields: FdFieldConfigs;
  public alive = true;
  public isCEF: boolean = false;
  public isMFA: boolean = false;
  public correlationId: string;
  public loginButtonActive = true;

  radioValue: string = '';
  isOptOptionDisabled: boolean = false;

  userEmail: string = '';
  userPhone: string = '';

  constructor(
    private formBuilder: FormBuilder,
    private dialogService: DialogService,
    private loginService: LoginService,
    private routingService: RoutingService,
    private dataStore: DataStore,
    private wizardService: WizardService,
    protected authService: AuthService,
    protected matDialog: MatDialog,
    private loadingService: LoadingService,
    private router: Router,
    private dataStoreService: DataStoreService,
    private cdr: ChangeDetectorRef
  ) {}

  public async ngOnInit() {
    this.startForms();

    const currentUrl = this.router.url;
    this.isCEF = currentUrl && currentUrl.indexOf('caixa') > -1;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  public getLogoPath() {
    return this.isCEF ? '/assets/images/caixa-logo.png' : '/assets/images/fiserv-logo.png';
  }

  public startForms(): void {
    this.formGroup = this.formBuilder.group({
      user: ['', Validators.required],
      password: ['', Validators.required],
      mfaToken: [],
      tokenChannel: [],
    });

    this.fields = {
      user: {
        label: 'CPF',
        disabled: false,
        controlName: 'user',
        mask: cpfMask,
        maskCharsReplace: /[. / -]/g,
        messages: {
          required: 'Informe um usuário válido',
          invalid: 'Usuário inválido',
        },
      },
      password: {
        label: 'Senha',
        disabled: false,
        controlName: 'password',
        type: InputType.PASSWORD,
        messages: {
          required: 'Informe a senha',
          invalid: 'Senha inválida',
        },
      },
      mfaToken: {
        label: 'Informe o token recebido',
        disabled: false,
        controlName: 'mfaToken',
        type: InputType.TEXT,
        messages: {
          required: 'Informe o token recebido',
          invalid: 'Token invalido',
        },
      },
    };
  }

  enableOtpOption() {
    this.formControls.tokenChannel.enable();
    this.formControls.tokenChannel.setValue('');
    this.radioValue = '';
    this.isOptOptionDisabled = false;
  }

  disableOtpOption(value: string) {
    this.radioValue = value;
    this.formControls.tokenChannel.disable();
    this.formControls.tokenChannel.setValue(value);
    this.formGroup.value.tokenChannel = value;
    this.isOptOptionDisabled = true;
  }

  async isRadioSelected(event) {
    const userCpfCnpj = this.formGroup.value.user;
    const userPassword = this.formGroup.value.password;
    const optionSelected = event.value;

    this.disableOtpOption(optionSelected);
    await this.sendOtpToken(userCpfCnpj, userPassword, optionSelected);
  }

  async sendOtpToken(username: string, password: string, sendBy: string) {
    try {
      const data = new LoginModel({ username: username, password: password, sendBy: sendBy });

      const correlationId = await this.loginService
        .sendOtpToken(data)
        .pipe(takeWhile(() => this.alive))
        .toPromise()
        .then((response) => response?.otpDetails?.correlationId || '');

      if (correlationId) {
        this.correlationId = correlationId;
        if (sendBy === 'SMS') {
          this.dialogService.openDialog(Messages.LOGIN_SEND_TOKEN_SMS);
        } else {
          this.dialogService.openDialog(Messages.LOGIN_SEND_TOKEN_EMAIL);
        }
      } else {
        this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_CORRELATIONID, () => this.routingService.navigateToLogin());
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async checkOtp() {
    try {
      const username = this.formGroup.value.user;
      const password = this.formGroup.value.password;
      const data = new LoginModel({ username: username, password: password });
      const response = await this.loginService
        .checkOtp(data)
        .pipe(takeWhile(() => this.alive))
        .toPromise();

      if (response.isOtpEnabled) {
        this.isMFA = true;
        this.loginButtonActive = true;
        this.userEmail = response.email;
        this.userPhone = response.telefoneCelular;
      } else {
        await this.executeLogin(data);
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async executeLogin(data) {
    try {
      const loginResponse: TokenModel = await this.loginService
        .login(data)
        .pipe(takeWhile(() => this.alive))
        .toPromise();
      if (loginResponse && this.authService.loginSuccess(loginResponse, this.isCEF)) {
        const userData = this.dataStoreService.getUserData();
        const isValidCef: Boolean = await this.cefValidation(userData);
        if (isValidCef) {
          this.routingService.navigateWithParentTo(
            startRoutingDefinitions.start.routes.WELCOME.parentRoute,
            startRoutingDefinitions.start.routes.WELCOME.name
          );
          this.loginButtonActive = true;
        } else {
          this.loginButtonActive = true;
          this.dialogService.openDialog(Messages.NO_TOKEN_DATA);
          throw new Error(Messages.NO_TOKEN_DATA.description);
        }
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async executeLoginMFA() {
    try {
      const username = this.formGroup.value.user;
      const password = this.formGroup.value.password;
      const correlationId = this.correlationId;
      const tokenOTP = this.formGroup.value.mfaToken;
      const sendBy = this.formGroup.getRawValue().tokenChannel;
      const data = new LoginModel({
        username: username,
        password: password,
        correlationId: correlationId,
        tokenOTP: tokenOTP,
        sendBy: sendBy,
      });

      const loginResponse: TokenModel = await this.loginService
        .loginMFA(data)
        .pipe(takeWhile(() => this.alive))
        .toPromise();

      if (loginResponse && this.authService.loginSuccess(loginResponse, this.isCEF)) {
        const userData = this.dataStoreService.getUserData();
        const isValidCef: Boolean = await this.cefValidation(userData);
        if (isValidCef) {
          this.routingService.navigateWithParentTo(
            startRoutingDefinitions.start.routes.WELCOME.parentRoute,
            startRoutingDefinitions.start.routes.WELCOME.name
          );
          this.loginButtonActive = true;
        } else {
          this.loginButtonActive = true;
          this.dialogService.openDialog(Messages.NO_TOKEN_DATA);
          throw new Error(Messages.NO_TOKEN_DATA.description);
        }
      }
    } catch (err) {
      this.handleError(err);
    }
  }

  async cefValidation(userData: UserDataModel): Promise<Boolean> {
    if (
      userData.institution.endsWith('7') &&
      userData.hierarchyComplementaryInformation.find((x) => x.subChannel === '99999') &&
      this.router.url.includes('/login')
    ) {
      this.dialogService.openDialog(Messages.FISERV_ONLINE_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.redirectCef());
      return false;
    }

    if (this.isCEF && !userData.institution.endsWith('7')) {
      this.dialogService.openDialog(Messages.SIMULATOR_NOT_AVAILABLE_FOR_SPECIFIED_LOGIN, () => this.authService.logoutCEF());
      return false;
    }

    if (this.isCEF) {
      localStorage.removeItem(Constants.ACTIVE_DRAFT_SESSIONSTORAGE_KEY);

      this.wizardService.goToSpecificStep(
        orderRoutingDefinitions.simulationData.stepNumber,
        orderRoutingDefinitions.simulationData.routes.FEE_SIMULATOR.order
      );
      return false;
    }
    return true;
  }

  handleError(err: any) {
    this.loginButtonActive = true;
    const serializedError = err.error ? (err.error as ApiResultModel) : null;

    if (serializedError && serializedError.message) {
      if (
        serializedError.message.includes('Could not open JPA EntityManager for transaction') ||
        serializedError.message.includes('Cannot get Jedis connection')
      ) {
        this.dialogService.openDialog(Messages.LOGIN_ERROR_UNAVAILABLE_BACKEND);
      } else if (serializedError && serializedError.data && serializedError.data.includes('USER_PASSWORD_EXPIRATED')) {
        this.dialogService.openDialogWithMessage(serializedError.message, () => this.getPasswordRecovery());
      } else {
        this.dialogService.openDialogWithMessage(serializedError.message);
      }
    } else {
      this.dialogService.openDialog(Messages.LOGIN_ERROR);
    }
  }

  public async login() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.NO_LOGIN_DATA);
      return;
    }

    if (this.isMFA && !this.radioValue) {
      this.dialogService.openDialog(Messages.LOGIN_ERROR_INVALID_SENDBY_OPTION);
      return;
    }

    this.loginButtonActive = false;

    if (this.isMFA) {
      await this.executeLoginMFA();
    } else {
      await this.checkOtp();
    }
  }

  public reenviarToken() {
    if (!this.formGroup.valid) {
      this.dialogService.openDialog(Messages.NO_LOGIN_DATA);
      return;
    }

    const username = this.formGroup.value.user;
    const password = this.formGroup.value.password;
    const sendBy = this.formGroup.getRawValue().tokenChannel;

    this.sendOtpToken(username, password, sendBy);
  }

  public ngOnDestroy() {
    this.alive = false;
  }

  public getPasswordRecovery() {
    return window.open(environment.ADMIN_URL + '/password-recovery?type=2');
  }

  public createDraft(): Proposal {
    const userData = this.dataStoreService.getUserData();
    return {
      serviceContract: userData.serviceContract,
      channel: userData.channel,
      subChannel: this.dataStoreService.getUserDataSubChannel(userData),
      agentChannel: this.dataStoreService.getUserDataAgentChannel(userData),
      institution: userData.institution,
      channelType: userData.channelType,
    };
  }

  private redirectCef() {
    this.authService.logoutCefRedirect();
  }

  get formControls() {
    return this.formGroup.controls;
  }
}
